
import { Minimize2Icon, FilmIcon, RepeatIcon, LoaderIcon, FileTextIcon, FileIcon } from 'vue-feather-icons'
import { NavigationLinks } from '../../../fc/Constants/config-navigation'

export default {
  name: 'NavigationLinksResponsive',
  components: {
    Minimize2Icon,
    FilmIcon,
    RepeatIcon,
    LoaderIcon,
    FileTextIcon,
    FileIcon,
    NestedLinks: () => import(/* webpackChunkName: "NestedLinks" */ '../../../components/ui/NestedLinks'),
    NavigationConverterList: () => import(/* webpackChunkName: "NavigationConverterList" */ '~/layouts/partials/navigation/NavigationConverterList')
  },
  computed: {
    NavigationLinks () { return NavigationLinks(this) },
    loggedIn () { return this.$auth ? this.$auth.Token : false }
  }
}
